.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.signup-link {
  text-align: center;
}