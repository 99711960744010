.App {
  /* margin-top: auto; */
}

.App .navbar {
  width: 100%;
}

.App .navbar-brand {
  font-weight: bold;
}
