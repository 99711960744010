.viewer {
  /* display: flex;
  flex-direction: column; */
  /* flex-shrink: 0; */
}

.content {
  /* display: flex; */
  /* justify-content: space-around; */
  /* overflow-y: scroll; */
  /* flex-shrink: 0; */
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.lister {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
}
